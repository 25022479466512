
import { EffectComposer, DepthOfField, Bloom } from "@react-three/postprocessing";
import { ContactShadows, AccumulativeShadows, RandomizedLight, Environment, BakeShadows, OrbitControls } from "@react-three/drei";
import Custom from "./Custom.jsx";
import { useControls } from "leva";
import { Perf } from 'r3f-perf'

export default function Experience()
{

  const environmentChoices={
    road_day:'./hdrs/evening_road_01_2k.hdr',
    night:'./hdrs/dikhololo_night_2k.hdr',
    london: './hdrs/london-night.hdr',
    winter_lake: './hdrs/winter_lake_01_2k.hdr',
    freight_station:'./hdrs/freight_station_2k.hdr',
    underpass:'./hdrs/derelict_underpass_2k.hdr',
    garage:'./hdrs/autoshop_01_2k.hdr',
    bus_depot:'./hdrs/old_bus_depot_2k.hdr',
    old_depot:'./hdrs/old_depot_2k.hdr',
    lake:'./hdrs/shudu_lake_2k.hdr',
    sunrise:'./hdrs/pink_sunrise_2k.hdr'
  }
  const { scale } = useControls('Vehicle',{
    scale: 1.1,
    
  })

  const { environment, envMapIntensity, envMapHeight, envMapRadius, envMapScale, bloom_intensity} = useControls('environment map', {
    environment: { options: environmentChoices },
    envMapIntensity: { value: 1.4, min:0, max:12},
    envMapHeight: { value:3, min:0, max:100},
    envMapRadius: { value:28, min:0, max:1000},
    envMapScale: { value:30, min:0, max:1000},
    bloom_intensity:{
      value:0.5,
      min:0.5,
      max:5,
      step:0.1
    }
  })

  const { focus_distance, focus_length, bokeh} = useControls('Depth of Field', {
    focus_distance:{value:0.12, max:1, min:0, step:0.02},
    focus_length:{value:0.22, max:1, min:0, step:0.02},
    bokeh:{value:1, max:1, min:0, step:0.02},
  })

  const{ shadowCaster_position } = useControls('Shadows',{
    shadowCaster_position:{ value:{ x:2, y:9, z:10}, step:0.5}
  })

  const { auto_rotate } = useControls('Controls',{
    auto_rotate: true
  })
    return <>

        <Perf position="top-left"/>

        <OrbitControls
          zoomSpeed={0.75}
          minPolarAngle={Math.PI / 2.2}
          maxPolarAngle={Math.PI / 2.2}
          maxZoom={40}
          target={[0, 0.7, 0]}
          autoRotateSpeed={-0.2}
          autoRotate={auto_rotate}
        />

        <Environment 
          
         files={environment}
         /*  ground={{ height: 3, radius: 30, scale: 30 }}  */
         ground={ {
          height: envMapHeight,
          radius: envMapRadius,
          scale: envMapScale
        }}
        background 
        blur = {0.5}
          />

        {/* <hemisphereLight intensity={0.5} color="white" groundColor="black" /> */}
        <hemisphereLight intensity={0.5} color="#756c65" groundColor="black" />
    
        <Custom scale={scale} rotation={[0, Math.PI / 1.7, 0]} />

        <AccumulativeShadows
          temporal
          frames={50}
          color="black"
          colorBlend={2}
          toneMapped={true}
          alphaTest={0.9}
          opacity={1}
          scale={14}
        >
          <RandomizedLight
            amount={6}
            radius={6}
            ambient={0.5}
            intensity={0.7}
            position={[shadowCaster_position.x, shadowCaster_position.y, shadowCaster_position.z]}
            bias={0.001}
          />
        </AccumulativeShadows>
        
        <EffectComposer multisampling={8} disableNormalPass={true}>
          <DepthOfField
            focusDistance={focus_distance}
            focalLength={focus_length}
            bokehScale={bokeh}
          />
          <Bloom mipmapBlur
            luminanceThreshold={0.3}
            luminanceSmoothing={0.9}
            height={300}
            opacity={1}
            intensity={bloom_intensity}
          />
        </EffectComposer>

    </>
}