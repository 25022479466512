/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import {  useControls } from 'leva'

export default function Custom(props) {
  const { nodes, materials } = useGLTF("/bodywork-test-4.glb");

  const { bodywork, headlight_intensity } = useControls('Vehicle',{
    bodywork: '#e25408',
    headlight_intensity: {
      value:15,
      min: 2,
      max: 30,
      step: 1
    }
  })

  const glassMaterial = new THREE.MeshPhysicalMaterial({
    color: 0x000000,
    metalness: 0,
    roughness: 0.1,
    reflectivity: 0.9,
    transmission: 0.95, // use material.transmission for glass materials
    opacity: 0.7,
    side: THREE.FrontSide,
    transparent: true
  });

  const bodyworkMaterial = new THREE.MeshPhysicalMaterial({
    metalness: 0,
    roughness: 0,
    /* encoding: THREE.sRGBEncoding, */
    clearcoat: 1,
    envMapIntensity: 0.8,
    /* color: "#e25408" */
    color: bodywork
  });

  const clearLightMaterial = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    opacity: 0.7,
    transmission: 0.8,
    metalness: 0.6,
    roughness: 0.1,
    reflectivity: 0.2,
    transparent: true,
    side: THREE.FrontSide
  });

  const redLightMaterial = new THREE.MeshPhysicalMaterial({
    /* color: 0xA1000B, */
    color: 0xf70000,
    opacity: 0.75,
    transmission: 0.8,
    metalness: 0.6,
    roughness: 0.1,
    reflectivity: 0.2,
    transparent: true,
    side: THREE.FrontSide
  });

  const lightGlow = new THREE.MeshStandardMaterial({
    color: '#ffffff',
    emissive:'#ffffff',
    emissiveIntensity: headlight_intensity,
    toneMapped: false
  });

  return (
    <group {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.exhaust.geometry}
          material={materials.Chrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.group2_polySurface1132.geometry}
          material={materials["light inners"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1110.geometry}
          material={materials.Cube}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1118.geometry}
          material={materials.SquareShallow_n}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1126.geometry}
          material={materials.Blackout_pxy}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh045.geometry}
          material={materials.Chrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh045_1.geometry}
          /* material={materials["new emissive lights"]} */
          material={ lightGlow}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1131.geometry}
          material={materials.SquareShallow_n}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1178.geometry}
          /* material={materials.Lens} */
          material={clearLightMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1187.geometry}
          /* material={materials.Red_Lens} */
          material={redLightMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1175.geometry}
          /* material={materials.light_clear} */
          material={clearLightMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1188.geometry}
          /* material={materials.Red_Lens} */
          material={redLightMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1231.geometry}
          /* material={materials.Red_Lens} */
          material={redLightMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1197.geometry}
          material={materials.Blackout_pxy}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1206.geometry}
          material={materials.Default_Material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1251.geometry}
          material={materials.Blackout_pxy}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1210.geometry}
          material={materials.SquareShallow_n}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1213.geometry}
          /* material={materials.Lens} */
          material={clearLightMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1255.geometry}
          /* material={materials.Lens} */
          material={clearLightMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1221.geometry}
          /* material={materials.Lens} */
          material={clearLightMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh146.geometry}
          material={materials.Chrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh146_1.geometry}
          /* material={materials["new emissive lights"]} */
          material={lightGlow}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1225.geometry}
          material={materials.Headlight_Lens}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1233.geometry}
          /*  material={materials.Red_Lens} */
          material={redLightMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1274.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1254.geometry}
          material={materials.Lens}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1275.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1384.geometry}
          material={materials.HardSruared}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh075.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh075_1.geometry}
          material={materials.rim_inner}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1456.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1460.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1461.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh119.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh119_1.geometry}
          material={materials.rim_inner}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1452.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1453.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1462.geometry}
          material={materials.Tire_Center}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1466.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1455.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1467.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1469.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1459.geometry}
          material={materials.Tire_side}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1463.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1417.geometry}
          material={materials.Paint_ColorChange_pxy}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1465.geometry}
          material={materials.Tire_Center}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1468.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1470.geometry}
          material={materials.Tire_side}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1473.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1484.geometry}
          material={materials.Licence_Plates}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1489.geometry}
          material={materials.Black_Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1504.geometry}
          material={materials.SquareShallow_n}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1507.geometry}
          material={materials.Chrome}
          position={[0, -0.69, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh039.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh039_1.geometry}
          material={materials.rim_inner}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh028.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh028_1.geometry}
          material={materials.rim_inner}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1586001.geometry}
          material={materials["whhel-housing-inner"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1588.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1590.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1593002.geometry}
          material={materials.Chrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1605.geometry}
          material={materials.MSRTrim}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1606.geometry}
          material={materials.OZRIM}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1586.geometry}
          material={materials.Blackout_pxy}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface588.geometry}
          material={materials.Tire_side}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1611.geometry}
          material={materials.Wheel_Logo}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface591.geometry}
          material={materials.Tire_Center}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface594.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1614.geometry}
          material={materials.Wheel_Parten}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface596.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.bodywork.geometry}
          /* material={materials.Paint_ColorChange_pxy}  */
          material={bodyworkMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface593.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface595.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface597.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface600.geometry}
          material={materials.Tire_side}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface604.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface606.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface603.geometry}
          material={materials.Tire_Center}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface608.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface605.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface607.geometry}
          material={materials.Rim_Color_alumanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface797.geometry}
          material={materials.Ford_Logo}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface822.geometry}
          material={materials.Default_Material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface889.geometry}
          material={materials.Blackout_pxy}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.side_panel.geometry}
          /* material={materials.Paint_ColorChange_pxy} */
          material={bodyworkMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.side_window1.geometry}
          /* material={materials.Window_side} */
          material={glassMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.side_window2.geometry}
          /* material={materials.Window_side} */
          material={glassMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.windscreen.geometry}
          /* material={materials.Window_side} */
          material={glassMaterial}
          scale={3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface776.geometry}
          material={materials.Interior}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1508.geometry}
          material={materials.Plastic}
        />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1096.geometry}
          material={materials.Chrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1099.geometry}
          material={materials.SquareShallow_n}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1184.geometry}
          material={materials.Ford_Logo}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1189.geometry}
          /* material={materials.Red_Lens} */
          material={redLightMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1282.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1283.geometry}
          material={materials.Paint_ColorChange_pxy}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["ford-surround-custom-tailgate"].geometry}
          material={materials.Chrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1485.geometry}
          material={materials.Licence_Plates}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1085.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface758.geometry}
          /* material={materials.Paint_ColorChange_pxy} */
          material={bodyworkMaterial}
        />
      </group>
    </group>
  );
}


